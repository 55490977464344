import React from 'react';
import PropTypes from 'prop-types';
import styled,{css} from 'styled-components';
import { Spin,Flex,Typography } from 'antd';
import {CenteredContent,absolute} from 'components/widget/container';
import Logo from 'components/widget/logo';

const Landing =styled(CenteredContent)`
    ${absolute}
    
`;
const Loading = ({type="default",style={},...other})=>(
type!="default"?
    <Landing full style={{background:"#001529",...style}}>
        <Flex vertical gap="large" style={{color:"white"}}>
            <Flex vertical gap="small">
                <Logo vertical logo={{size:"6x"}}/>
                <Typography.Title level={5} style={{color:"white",margin:0,fontWeight:400}}>
                    {type}
                </Typography.Title>
            </Flex>
            <Spin size="large" {...other}/>
            
        </Flex>
    </Landing>
    :
    <CenteredContent full style={style}>
        <Spin size="large" {...other}/>    
    </CenteredContent>
);

Loading.propTypes = {
  
}; 

export default Loading