import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {Flex} from 'antd';
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons/faCanadianMapleLeaf';
import { faCubesStacked } from '@fortawesome/free-solid-svg-icons/faCubesStacked'

export const breakPoints = {
  palm: 588,
  desk: 768
};

const Container = styled(Flex)`
  @media (max-width: ${breakPoints.desk}px) {
    h1 {
      display:none;
    }
        
  }
`;
const Logo= ({
logo,
title="MODOLO",
style,
styles:{logo:logoStyle={},title:titleStyle}={},
...props
})=><Container align="center" gap={12} style={{color:"white",...style}} {...props}>
  <FontAwesomeIcon {...{
        "size":"3x",        
        "mask":faCanadianMapleLeaf,
        "transform": "shrink-10",
        "icon": faCubesStacked,
        ...logo,
        "style":{...logoStyle},
    }}/>
    {title&&<h1 style={{fontFamily:"var(--ant-font-family)",margin:0,...titleStyle}}>{title}</h1>}
    </Container>


Logo.propTypes = {
  
}; 

export default Logo;