import React from 'react';
import { useNavigate as _useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import _ from 'lodash';
import {filesize} from "filesize";

export const CATCHPHRASE= "... cloud-based data sharing, visualization, and analysis ...";
export const LOADINGPAGE= "... loading web page ...";


export const to = (promise) => (promise.then(data => [null, data]).catch(err => [err]));
export const dateToLocal = (o) => o&&dayjs(o).format('YYYY-MM-DD HH:mm:ss A');

export const getFolderKey = (Key="") => {
  const parts = Key.split('/');
  const nonEmptyParts = parts.filter(part => part !== '');
  nonEmptyParts.pop();
  const folderKey = nonEmptyParts.join('/');
  return folderKey == "" ? undefined : folderKey + "/";
};

export const getCurrentFolder=(Key="")=>Key.endsWith('/')?Key:getFolderKey(Key);

export const usePrevious=(value)=> {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}
export default usePrevious;

export const getKeyBasename = (Key="") => {
  const cleanedPath = (Key||"").replace(/\/$/, '');
  const parts = cleanedPath.split('/');
  return parts[parts.length - 1];
};

export const getBasenameWithoutExtension=(Key="")=>{  
  
  const basename = (Key||"").split('/').pop();  
  const parts = basename.split('.');
  return parts.length > 1 ? parts.slice(0, -1).join('.') : basename;
};

export const geKeyWithoutExtension=(Key="")=>{  
  const basename = (Key||"").split('/').pop();  
  const parts = basename.split('.');
  const name = parts.length > 1 ? parts.slice(0, -1).join('.') : basename; 
  
  return name == "" ? getFolderKey(Key) : getFolderKey(Key)+name; 
};

export const  hasFileExtension=(str)=>{
  // Regular expression to match file extensions
  const extensionPattern = /\.[0-9a-z]+$/i;

  // Test if the string ends with a valid file extension
  return extensionPattern.test(str);
}

export const isFolder = (Key="/") => Key.endsWith('/');

export const dict2array=(d)=> {
  return Object.entries(d).map(([key, value]) => ({ 'name': key, 'value': value }));
};

export const changeFileExtension=(filePath, newExtension)=> {
    if(!filePath)return;
    // Use regular expression to replace the extension
    return filePath.replace(/\.[^/.]+$/, '.' + newExtension);
}



export const createImageURL = (binaryData,type) => {
    const blob = new Blob([binaryData], { type }); // Adjust MIME type as necessary
    return URL.createObjectURL(blob);
};

export const fileSize=(size)=>size&&filesize(size, {base: 2, standard: "jedec"});

const dateReviver=(key, value)=>(typeof value === 'number')?new Date(value):value;
// const dateReplacer=(key, value)=>console.log(key,value)||key=="expiration"?value.getTime():value;


export const JSONparseDate = (o)=>JSON.parse(o, dateReviver);
// export const JSONstringitfyDate = (o)=>JSON.stringify(o, dateReplacer);

export const parseJson = (value) => {
  try {
    const json = JSON.parse(value);
    return [null, json];
  }
  catch (e) {
    return [e];
  }
};

export const getFileExtension=(path)=>{
  if(!path)return null;
    const parts = path.split('.');
    if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {        
        return null;
    }
    return parts.pop().toLowerCase();
}

export const splitPathAndQuery = (raw) => {
    const [path, query = ""] = raw.split('?');
    
    const queryObject = {};
    
    // Convert query string into an object
    if (query) {
        const params = new URLSearchParams(query);
        for (const [key, value] of params.entries()) {
            queryObject[key] = value;
        }
    }
    
    return {
        path,
        query: queryObject
    };
}

export const getRelativePath = (Key, Prefix) => Key.substring(Prefix.length);

export const removeKeysWithPrefix=(obj, prefix)=>{
    for (const key in obj) {
        if (key.startsWith(prefix) && key!==prefix) {
            delete obj[key];
        }
    }
    return obj;
}

export const S3List2Tree = (tree) => {
    return Object.keys(tree).map(key => ({
      "id": key,
      "parent": tree[getFolderKey(key)] ? getFolderKey(key) : 0,
      "droppable": isFolder(key),
      "text": getKeyBasename(key),
      "data": tree[key]
    }));    
};

export const useNavigate=()=>{
   try {
    return _useNavigate();
  } catch (error) {
    return null;
  }
};

export const useQueryParams = (location={}) => {
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const paramsObject = {};

  queryParams.forEach((value, key) => {
    paramsObject[key] = value;
  });

  return paramsObject;
};

// export const useParentState = (_value, _setValue) =>(_setValue)?[_value,_setValue]:React.useState();
